<template>
  <div
    class="onboarding-container"
    :class="{ 'default-padding': showDefaultPadding }"
    data-test="onboarding-container"
  >
    <div class="loading-container" v-if="loading">
      <b-spinner />
    </div>
    <AccountPurpose
      v-if="screen === OnboardingSteps.PURPOSE"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <BusinessType
      v-if="screen === OnboardingSteps.BUSINESS_TYPE"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <BusinessConfirmSoleProprietor
      v-if="screen === OnboardingSteps.BUSINESS_CONFIRM_SOLE_PROPRIETOR"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <BusinessNotSoleProprietor
      v-if="screen === OnboardingSteps.BUSINESS_NOT_SOLE_PROPRIETOR"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <BusinessAdditionalInfoSuccess
      v-if="screen === OnboardingSteps.BUSINESS_INFO_SUCCESS"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <SignupPhone
      v-if="screen === OnboardingSteps.PHONE"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <SignupPhoneConfirm
      v-if="screen === OnboardingSteps.PHONE_CONFIRM"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <!-- Used for org flow -->
    <SignupInfo
      v-if="screen === OnboardingSteps.INFO"
      @complete-step="onComplete"
      :showPhone="true"
      :fullpage="fullpage"
    />
    <SignupBasicInfo
      v-if="screen === OnboardingSteps.BASIC_INFO"
      @complete-step="onComplete"
      :fullpage="fullpage"
      :showProgressTracker="true"
    />
    <SignupBillingAddress
      v-if="screen === OnboardingSteps.BILLING_ADDRESS"
      @complete-step="onComplete"
      :fullpage="fullpage"
      :showProgressTracker="true"
    />
    <SignupSSNLastFour
      v-if="screen === OnboardingSteps.SSN_LAST_FOUR"
      @complete-step="onComplete"
      :fullpage="fullpage"
      :showProgressTracker="true"
    />
    <SignupReviewDetails
      v-if="screen === OnboardingSteps.REVIEW_DETAILS"
      @complete-step="onComplete"
      :fullpage="fullpage"
      :showProgressTracker="true"
    />
    <VerifySsn
      v-if="screen === OnboardingSteps.VERIFY_SSN"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <FundingType
      v-if="screen === OnboardingSteps.FUNDING_TYPE"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <FundingTypeCharge
      v-if="screen === OnboardingSteps.FUNDING_TYPE_CHARGE"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <FundingTypeBusiness
      v-if="screen === OnboardingSteps.FUNDING_TYPE_BUSINESS"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <AddCard
      v-if="screen === OnboardingSteps.ADD_CARD"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <AddCard
      v-if="screen === OnboardingSteps.ADD_CARD_REROUTED"
      @complete-step="onComplete"
      :isRerouted="true"
      :fullpage="fullpage"
    />
    <ConfirmCard
      v-if="screen === OnboardingSteps.CONFIRM_CARD"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <SuccessCard
      v-if="screen === OnboardingSteps.SUCCESS_CARD"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <SuccessCardBatch
      v-if="screen === OnboardingSteps.SUCCESS_CARD_BATCH"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <AddFunding
      v-if="screen === OnboardingSteps.ADD_BANK"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <ConfirmBank
      v-if="screen === OnboardingSteps.CONFIRM_BANK"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <SuccessBank
      v-if="screen === OnboardingSteps.SUCCESS_BANK"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <ConsumerChargeDisclosure
      v-if="screen === OnboardingSteps.CHARGE_DISCLOSURE"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <CommercialChargeDisclosure
      v-if="screen === OnboardingSteps.COMMERCIAL_CHARGE_DISCLOSURE"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <FlaggedAccount
      v-if="screen === OnboardingSteps.FLAGGED_ACCOUNT"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <ApplicationComplete
      v-if="screen === OnboardingSteps.APPLICATION_COMPLETE"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <SignupComplete
      v-if="screen === OnboardingSteps.SIGNUP_COMPLETE"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <AdditionalVerification
      v-if="screen === OnboardingSteps.ADDITIONAL_VERIFICATION"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <Disclosures
      v-if="screen === OnboardingSteps.LEGACY_DISCLOSURES"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <ExistingUserVerifySsn
      v-if="screen === OnboardingSteps.EXISTING_USER_VERIFY_SSN"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <ConsumerChargeDisclosure
      v-if="screen === OnboardingSteps.EXISTING_USER_CHARGE_DISCLOSURE"
      @complete-step="onComplete"
      :fullpage="fullpage"
      :existingUser="true"
    />
    <ChargeTermsSuccess
      v-if="screen === OnboardingSteps.CHARGE_TERMS_SUCCESS"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <SignupReviewDetails
      v-if="screen === OnboardingSteps.REMEDIATION_RETRY"
      @complete-step="onComplete"
      :fullpage="fullpage"
      :showProgressTracker="true"
      :kycFailure="true"
    />
    <RemediationSuccess
      v-if="screen === OnboardingSteps.REMEDIATION_SUCCESS"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <RemediationPending
      v-if="screen === OnboardingSteps.REMEDIATION_PENDING"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <RemediationFailure
      v-if="screen === OnboardingSteps.REMEDIATION_FAILURE"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <AddFunds
      v-if="screen === OnboardingSteps.ADD_FUNDS"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <Billing
      v-if="screen === OnboardingSteps.BILLING"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <BusinessInfo
      v-if="screen === OnboardingSteps.DEPRECATED_BUSINESS_INFO"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <BusinessInfoAbout
      v-if="screen === OnboardingSteps.DEPRECATED_BUSINESS_INFO_ABOUT"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <BusinessInfoLegal
      v-if="screen === OnboardingSteps.DEPRECATED_BUSINESS_INFO_LEGAL"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <BusinessOwners
      v-if="screen === OnboardingSteps.DEPRECATED_BUSINESS_OWNERS"
      @complete-step="onComplete"
      :fullpage="fullpage"
    />
    <BusinessDetails
      v-if="screen === OnboardingSteps.BUSINESS_DETAILS"
      @complete-step="onComplete"
      :fullpage="fullpage"
      :showProgressTracker="true"
    />
    <BusinessExecs
      v-if="screen === OnboardingSteps.BUSINESS_EXECS"
      @complete-step="onComplete"
      :fullpage="fullpage"
      :showProgressTracker="true"
    />
    <BusinessUse
      v-if="screen === OnboardingSteps.BUSINESS_USE"
      @complete-step="onComplete"
      :fullpage="fullpage"
      :showProgressTracker="true"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { OnboardingSteps, onboardingStepName } from "@/types/Onboarding";
import { userStore } from "@/store/index";

import AccountPurpose from "@/views/signup/AccountPurpose.vue";
import SignupInfo from "@/views/signup/SignupInfo.vue";
import SignupBasicInfo from "@/views/signup/SignupBasicInfo.vue";
import SignupBillingAddress from "@/views/signup/SignupBillingAddress.vue";
import SignupPhone from "@/views/signup/SignupPhone.vue";
import SignupPhoneConfirm from "@/views/signup/SignupPhoneConfirm.vue";
import VerifySsn from "@/views/VerifySsn.vue";
import SignupSSNLastFour from "@/views/signup/SignupSSNLastFour.vue";
import SignupReviewDetails from "@/views/signup/SignupReviewDetails.vue";
import FundingType from "@/views/funding/FundingType.vue";
import FundingTypeCharge from "@/views/funding/FundingTypeCharge.vue";
import FundingTypeBusiness from "@/views/funding/FundingTypeBusiness.vue";
import AddCard from "@/views/funding/AddCard.vue";
import ConfirmCard from "@/views/funding/ConfirmCard.vue";
import SuccessCard from "@/views/funding/SuccessCard.vue";
import SuccessCardBatch from "@/views/funding/SuccessCardBatch.vue";
import AddFunding from "@/views/funding/AddFunding.vue";
import ConfirmBank from "@/views/funding/ConfirmBank.vue";
import SuccessBank from "@/views/funding/SuccessBank.vue";
import ConsumerChargeDisclosure from "@/views/funding/ConsumerChargeDisclosure.vue";
import CommercialChargeDisclosure from "@/views/funding/CommercialChargeDisclosure.vue";
import FlaggedAccount from "@/views/funding/FlaggedAccount.vue";
import ApplicationComplete from "@/views/signup/ApplicationComplete.vue";
import SignupComplete from "@/views/signup/SignupComplete.vue";
import AdditionalVerification from "@/components/AdditionalVerification.vue";
import Disclosures from "@/views/signup/Disclosures.vue";
import ExistingUserVerifySsn from "@/views/ExistingUserVerifySsn.vue";
import ChargeTermsSuccess from "@/views/funding/ChargeTermsSuccess.vue";
import RemediationSuccess from "@/views/RemediationSuccess.vue";
import RemediationPending from "@/views/RemediationPending.vue";
import RemediationFailure from "@/views/RemediationFailure.vue";
import AddFunds from "@/components/modals/AddFunds.vue";
import Billing from "@/views/account/business-info/Billing.vue";
import BusinessInfo from "@/views/account/business-info/BusinessInfo.vue";
import BusinessInfoAbout from "@/views/account/business-info/BusinessInfoAbout.vue";
import BusinessInfoLegal from "@/views/account/business-info/BusinessInfoLegal.vue";
import BusinessOwners from "@/views/account/business-info/BusinessOwners.vue";
import BusinessDetails from "@/views/account/business-info/BusinessDetails.vue";
import BusinessExecs from "@/views/account/business-info/BusinessExecs.vue";
import BusinessUse from "@/views/account/business-info/BusinessUse.vue";
import BusinessType from "@/views/account/business-info/BusinessType.vue";
import BusinessConfirmSoleProprietor from "@/views/account/business-info/BusinessConfirmSoleProprietor.vue";
import BusinessNotSoleProprietor from "@/views/account/business-info/BusinessNotSoleProprietor.vue";
import BusinessAdditionalInfoSuccess from "@/views/account/business-info/BusinessAdditionalInfoSuccess.vue";
import { NavigationGuardNext, Route } from "vue-router";

@Component({
  components: {
    AccountPurpose,
    SignupInfo,
    SignupBasicInfo,
    SignupBillingAddress,
    SignupPhone,
    SignupPhoneConfirm,
    VerifySsn,
    SignupSSNLastFour,
    SignupReviewDetails,
    FundingType,
    FundingTypeCharge,
    FundingTypeBusiness,
    AddCard,
    ConfirmCard,
    SuccessCard,
    SuccessCardBatch,
    AddFunding,
    ConfirmBank,
    SuccessBank,
    ConsumerChargeDisclosure,
    CommercialChargeDisclosure,
    FlaggedAccount,
    ApplicationComplete,
    SignupComplete,
    AdditionalVerification,
    Disclosures,
    ExistingUserVerifySsn,
    ChargeTermsSuccess,
    RemediationSuccess,
    RemediationPending,
    RemediationFailure,
    AddFunds,
    Billing,
    BusinessInfo,
    BusinessInfoAbout,
    BusinessInfoLegal,
    BusinessOwners,
    BusinessDetails,
    BusinessExecs,
    BusinessUse,
    BusinessType,
    BusinessConfirmSoleProprietor,
    BusinessNotSoleProprietor,
    BusinessAdditionalInfoSuccess,
  },
})
export default class OnboardingContainer extends Vue {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop({ default: false }) isAddingFunding!: boolean;
  @Prop({ default: false }) isSwitchingToAutomatic!: boolean;
  @Prop({ default: null }) startingScreen!: OnboardingSteps;

  OnboardingSteps = OnboardingSteps;
  screen: OnboardingSteps | null = null;
  loading = false;

  get showDefaultPadding() {
    return ![
      OnboardingSteps.CHARGE_DISCLOSURE,
      OnboardingSteps.EXISTING_USER_CHARGE_DISCLOSURE,
    ].includes(this.screen as OnboardingSteps);
  }

  mounted() {
    if (userStore.getters.currentUser?._id) {
      this.onMount();
    } else {
      this.loading = true;
      userStore.actions.getCurrentUser().then(() => {
        this.loading = false;
        this.onMount();
      });
    }
  }

  beforeRouteEnter(
    to: Route,
    from: Route,
    next: NavigationGuardNext<OnboardingContainer>
  ) {
    next((vm: OnboardingContainer) => {
      if (userStore.getters.currentUser?._id) {
        vm.onMount();
      }
    });
  }

  @Watch("screen")
  onScreenChange(newScreen: OnboardingSteps) {
    if (!this.fullpage) {
      this.$piwik.trackEvent("Modal", "View", {
        name: onboardingStepName(newScreen),
      });
    }

    // TODO should we block users from going back to some old screens?
    if (this.fullpage && newScreen !== this.startingScreen) {
      if (this.isTerminalScreen(newScreen)) {
        this.$router.push({ name: "home" });
        return;
      }

      const newRouteName = `onboarding-${newScreen}`;
      this.$router.push({ name: newRouteName });
    }
  }

  onMount() {
    // isAddingFunding is only passed to nextOnboardingStep on mount, not in onComplete.
    // Thus, it will not be taken into consideration mid-flow, ex. if a user has incomplete KYC
    // when they try to add a funding source.  This avoids an infinite loop but makes these
    // users have to re-enter the flow to add funding.
    const nextScreen = userStore.getters.nextOnboardingStep(
      this.isAddingFunding,
      this.isSwitchingToAutomatic
    );

    if (this.startingScreen !== null && this.startingScreen <= nextScreen) {
      this.screen = this.startingScreen;
    } else {
      this.screen = nextScreen;
    }

    if (this.isTerminalScreen(this.screen)) {
      const currentUser = userStore.getters.currentUser;
      if (currentUser?.isRemediatingKYC) {
        // Translate from string saved in mongo to enum
        const enumRemediationStep =
          // @ts-ignore this will be OnboardingSteps | undefined
          OnboardingSteps[currentUser.currentRemediationStep];
        if (enumRemediationStep !== undefined) {
          this.screen = enumRemediationStep as OnboardingSteps;
        } else {
          this.screen = OnboardingSteps.BASIC_INFO;
        }
      } else {
        this.$nextTick(() => {
          this.onFinishedOnboarding();
        });
      }
    }
  }

  getNextEditingScreen() {
    switch (this.screen) {
      case OnboardingSteps.BASIC_INFO:
        return OnboardingSteps.BILLING_ADDRESS;
      case OnboardingSteps.BILLING_ADDRESS:
        return OnboardingSteps.SSN_LAST_FOUR;
      case OnboardingSteps.SSN_LAST_FOUR:
        return OnboardingSteps.REVIEW_DETAILS;
      default:
        return OnboardingSteps.BASIC_INFO;
    }
  }

  onComplete(forcedScreen: OnboardingSteps) {
    if (forcedScreen !== undefined) {
      this.screen = forcedScreen;
    } else if (userStore.getters.currentUser?.isRemediatingKYC) {
      // The initial steps of remediation are the same as editing,
      // and later steps will use the forcedScreen mechanism
      this.screen = this.getNextEditingScreen();
      userStore.actions.updateRemediationStep(this.screen);
    } else if (userStore.getters.currentUser?.isEditingDetails) {
      this.screen = this.getNextEditingScreen();
    } else {
      this.screen = userStore.getters.nextOnboardingStep();
    }

    if (this.isTerminalScreen(this.screen)) {
      this.onFinishedOnboarding();
    }
  }

  onFinishedOnboarding() {
    this.$emit("finished-onboarding");
  }

  isTerminalScreen(screen: OnboardingSteps) {
    return [OnboardingSteps.COMPLETE, OnboardingSteps.BLOCKED].includes(screen);
  }
}
</script>

<style lang="scss" scoped>
.default-padding {
  padding: 0 2.5rem 2.5rem;
}

.loading-container {
  display: flex;
  justify-content: center;
  margin-top: 20vh;
}
</style>
