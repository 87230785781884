<template>
  <SimplePage :fullpage="fullpage" :defaultPadding="false">
    <BaseForm
      class="disclosure"
      :class="{ 'scroll-terms': !fullpage }"
      :fullpage="fullpage"
      @submit="submit"
      data-test="new-user-charge-disclosure"
    >
      <h1>{{ existingUser ? "Upgrade Your Account" : "One more step!" }}</h1>
      <p class="mb-4 mx-4">
        We just have to cover the boring legal stuff before you're done.
      </p>
      <div class="content-wrapper">
        <div class="content-table">
          <p class="font-weight-bold">
            Key Information About Your Privacy Card
          </p>
          <table>
            <thead>
              <tr>
                <th>Payment Information</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  All charges made on this charge card are due and payable when
                  your periodic statement is received.
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th colspan="2">Fees</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="font-weight-bold">Annual Fee</td>
                <td>
                  <p v-for="plan in plans" :key="plan.type">
                    <strong>{{ annualPlanAmount(plan) }}</strong>
                    for users on Privacy's {{ plan.name }} tier (paid monthly).
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="font-weight-bold">Transaction Fees</p>
                  <ul>
                    <li>Foreign Transactions</li>
                  </ul>
                </td>
                <td>
                  <p>
                    For each transaction, the greater of <strong>3%</strong> of
                    the foreign transaction in US dollars or
                    <strong>$0.50</strong> for users on Privacy's Personal or
                    Plus tier.
                  </p>
                  <p>
                    Users on Privacy's Pro or Premium tier have no foreign
                    transaction fees.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  For Charge Card Tips from the Consumer Financial Protection
                  Bureau
                </td>
                <td>
                  To learn more about factors to consider when applying for or
                  using a charge card, visit the website of the Consumer
                  Financial Protection Bureau at
                  <b-link
                    href="http://www.consumerfinance.gov/learnmore"
                    target="_blank"
                    >http://www.consumerfinance.gov/learnmore</b-link
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <p class="text-left">
            <strong>Billing Rights:</strong> Information on your rights to
            dispute transactions and how to exercise those rights is provided in
            your cardholder agreement.
          </p>
        </div>
        <div class="content-list mb-4">
          <p>
            To finish, you need to apply for a charge card with our bank
            partner, Patriot Bank N.A.
          </p>
          <p class="font-weight-bold">
            By clicking “Agree” below, you acknowledge and agree:
          </p>
          <ul>
            <li>
              That you will have to pay your card balance in full at the end of
              each billing cycle
            </li>
            <li>
              To help the government fight terrorism funding and money
              laundering, federal law requires that all financial institutions
              obtain, verify, and record identifying information about everyone
              who opens an account. Because of this, when you open an account
              with us, we will ask for your name, address, date of birth, and
              other information (which may include copies of drivers licenses or
              other documents) that identifies you.
            </li>
          </ul>
        </div>

        <div v-if="existingUser">
          <p class="text-left">
            Once your request has been approved, you will be able to generate
            new Privacy Cards via our bank partner, Patriot Bank N.A.
          </p>
          <p class="font-weight-bold">Automatic Payments</p>
          <div class="mb-3 d-flex justify-content-center">
            <FundingSourceComponent
              :source="defaultFundingSource"
              v-if="defaultFundingSource"
            />
          </div>
          <p class="text-left mb-1" v-if="defaultFundingSource">
            Your connected funding source will be used to fund purchases you
            make on Privacy cards.
          </p>
          <p class="text-left">
            By agreeing, you're enrolling in the automatic funding of purchases.
          </p>
          <b-link
            @click="showManualPaymentsInstructions"
            aria-label="manual payment instructions"
            >Other payment types</b-link
          >
        </div>
      </div>
      <div class="footer">
        <p class="font-weight-bold font-italic mt-4">
          This will not affect your credit score.
        </p>
        <p class="text-left">
          By clicking “Agree”, you are bound by the
          <b-link
            href="https://privacy.com/cardholder-agreement"
            target="_blank"
            >Privacy.com Charge Cardholder Agreement</b-link
          >
          and
          <b-link
            href="https://bankpatriot.com/PatriotBank/media/Documents/Privacy_Policy.pdf"
            target="_blank"
            >Patriot Bank's GLBA Notice</b-link
          >
          if your application is accepted.
        </p>
        <BaseButton
          class="mt-2"
          type="submit"
          variant="primary"
          size="sm"
          data-test="agree-apply"
          @click="trackClick"
        >
          {{
            existingUser ? "Request Access to New Cards" : "Agree & Continue"
          }}
        </BaseButton>
      </div>
    </BaseForm>
    <ManualPaymentsExplainer
      @manual-payments="onClickManualPayments"
      @automatic-payments="onClickAutomaticPayments"
    ></ManualPaymentsExplainer>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from "vue-property-decorator";
import { userStore, cardStore, eventStore, subscriptionStore } from "@/store";
import { BAlert } from "bootstrap-vue";
import SVGIcon from "@/components/SVGIcon.vue";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";
import ManualPaymentsExplainer from "@/components/ManualPaymentsExplainer.vue";
import FundingSourceComponent from "@/components/FundingSource.vue";
import { EVENTS } from "@/types/Event";
import { PlanData } from "@/types/Subscription";
import { Toast } from "@/mixins/Toast";
import ldGet from "lodash/get";

@Component({
  components: {
    BAlert,
    SVGIcon,
    ManualPaymentsExplainer,
    FundingSourceComponent,
  },
})
export default class ConsumerChargeDisclosure extends Mixins(Toast) {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop({ default: false }) existingUser!: boolean;

  get user() {
    return userStore.getters.currentUser;
  }

  get cardList() {
    return cardStore.getters.cardList();
  }

  get defaultFundingSource() {
    if (!this.user) {
      return null;
    }

    const { bankAccountList = [], fundingCardList = [] } = this.user;
    return (
      [...bankAccountList, ...fundingCardList].find(
        (source) => source.default
      ) || null
    );
  }

  get plans() {
    return subscriptionStore.getters.plans;
  }

  annualPlanAmount(plan: PlanData) {
    // monthly amount in cents * 12 months
    return `$${(plan.amount / 100) * 12}`;
  }

  trackClick(): void {
    const type = this.existingUser
      ? OnboardingSteps.EXISTING_USER_CHARGE_DISCLOSURE
      : OnboardingSteps.CHARGE_DISCLOSURE;

    this.$piwik.trackClick({
      name: `${onboardingStepName(type)} Primary CTA`,
    });
  }

  submit() {
    if (this.existingUser) {
      eventStore.actions.record({
        name: EVENTS.CTA.CLICKED,
        data: {
          buttonContext: "Existing User Charge Disclosure",
          buttonName: "Apply and Enroll",
        },
      });
      userStore.actions
        .automaticPaymentsEnroll()
        .then((): Promise<any> => {
          return userStore.actions.chargeCardAgree();
        })
        .then(() => {
          this.$emit("complete-step", OnboardingSteps.CHARGE_TERMS_SUCCESS);
        })
        .catch((resp) => {
          this.errorToast(
            ldGet<any, any, string>(
              resp?.response,
              "data.message",
              "Failed to accept charge terms."
            )
          );
        });
    } else {
      userStore.actions
        .chargeCardAgree()
        .then(() => {
          if (this.user?.applicationDeclined) {
            this.$emit("complete-step", OnboardingSteps.FLAGGED_ACCOUNT);
          } else if (
            this.user?.verificationNeeded ||
            this.user?.isPendingBusinessReview
          ) {
            this.$emit("complete-step", OnboardingSteps.APPLICATION_COMPLETE);
          } else {
            cardStore.actions.activateSampleCard().then(() => {
              this.$emit("complete-step", OnboardingSteps.SIGNUP_COMPLETE);
            });
          }
        })
        .catch((resp) => {
          this.errorToast(
            ldGet<any, any, string>(
              resp?.response,
              "data.message",
              "Failed to accept charge terms."
            )
          );
        });
    }
  }

  cancel() {
    this.$emit("complete-step", OnboardingSteps.BLOCKED);
  }

  showManualPaymentsInstructions() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.CHARGE_DISCLOSURE)} Other Payment Types CTA`,
    });

    eventStore.actions.record({
      name: EVENTS.CTA.CLICKED,
      data: {
        buttonContext: "Existing User Charge Disclosure",
        buttonName: "Other Payment Types",
      },
    });
    this.$bvModal.show("manual-payments-explainer");
  }

  onClickAutomaticPayments() {
    this.$bvModal.hide("manual-payments-explainer");
  }

  onClickManualPayments() {
    if (this.user?.manualApplicationSentTime) {
      this.$bvModal.hide("manual-payments-explainer");
    } else {
      eventStore.actions.record({
        name: EVENTS.CTA.CLICKED,
        data: {
          buttonContext: "Existing User Charge Disclosure",
          buttonName: "Manual Payments",
        },
      });
      userStore.actions.manualPaymentsApply().then(() => {
        this.$bvModal.hide("manual-payments-explainer");
        this.$emit("complete-step");
      });
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .form {
  padding: 0;
  width: 580px;
}

::v-deep .simple-page {
  padding: 0;
}

.disclosure {
  text-align: center;
  opacity: 0;
  animation: show-step $duration-default;
  animation-timing-function: $ease-out-expo;
  animation-fill-mode: forwards;

  > h1 {
    all: unset;
    margin-bottom: 15px;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    color: $gray-800;
    line-height: 1.4;
  }

  .content-wrapper {
    font-size: 12px;
    line-height: 1.3;
    padding: 0 40px;
  }

  .content-list {
    padding: 16px;
    background: $gray-100;
    border-radius: $border-radius;
    color: $gray-800;
    text-align: left;
    white-space: pre-line;

    > ul {
      padding-inline-start: 20px;
      margin-bottom: 0;

      > li:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    p {
      margin-bottom: 8px;
    }
  }

  .content-table {
    table {
      text-align: left;
      margin-bottom: 16px;
      width: 100%;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;

      p {
        margin-bottom: 8px;
      }

      ul {
        padding-inline-start: 20px;
        margin-bottom: 0;
      }
    }

    th {
      background-color: $accent-20;
    }

    th,
    td {
      border: 1px solid $accent-20;
      padding: 8px;
    }
  }

  .footer {
    padding: 0 40px 40px;
  }

  button {
    width: 100%;
  }

  &.scroll-terms {
    .content-wrapper {
      max-height: 330px;
      overflow-y: scroll;
      padding-bottom: 20px;
    }

    .footer {
      box-shadow:
        0px 0px 0px 1px rgba(0, 0, 0, 0.08),
        0px -4px 12px 0px rgba(0, 0, 0, 0.1);
      border-bottom-left-radius: $radius-larger;
      border-bottom-right-radius: $radius-larger;
      overflow: hidden;
    }
  }

  @media #{$media-width-540} {
    .content-wrapper,
    .footer {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>
