<template>
  <b-modal
    id="card-type-modal"
    @show="onShow"
    centered
    size="lg"
    hide-footer
    title="Create New Virtual Card"
    header-class="type-modal-header"
    content-class="shadow"
    body-class="type-modal-body"
    data-test="card-type-modal"
  >
    <button
      aria-label="Virtual Card"
      @click="select('')"
      data-test="select-locked-card-type"
    >
      <div class="card-type">
        <SVGIcon class="card-type-icon" icon="card"></SVGIcon>
        <div class="text">
          <p class="title">Virtual Card</p>
          <p>
            Create a virtual card for your online purchases or phone orders.
          </p>
        </div>
        <SVGIcon icon="chevron-right"></SVGIcon>
      </div>
    </button>
    <button
      aria-label="Category-Locked Card"
      @click="select('cl')"
      data-test="select-cl-card-type"
      :disabled="!canCreateCategoryCards"
      class="category-card-button"
    >
      <div
        class="card-type"
        :class="{ '-with-promo': !canCreateCategoryCards }"
      >
        <SVGIcon
          class="card-type-icon"
          :class="{ 'disabled-icon': !canCreateCategoryCards }"
          icon="grid"
        ></SVGIcon>
        <div class="text">
          <p class="title">Category-Locked Card</p>
          <p>
            Create a virtual card to use with multiple merchants that qualify
            for for specific category types.
          </p>
        </div>
        <SVGIcon
          icon="chevron-right"
          :class="{ 'disabled-icon': !canCreateCategoryCards }"
        ></SVGIcon>
      </div>
      <b-link to="/select-plan" class="link">
        <div v-if="!canCreateCategoryCards" class="upgrade-prompt">
          <b-badge variant="dark"> UPGRADE REQUIRED </b-badge>
          <p class="category-card-upgrade">
            Upgrade to a paid plan to enable Category-Locked cards
          </p>
        </div>
      </b-link>
    </button>
    <button
      v-if="canCreateDigitalWalletCards"
      aria-label="Digital Wallet Card"
      @click="select('dw')"
      data-test="select-dw-card-type"
    >
      <div class="card-type">
        <SVGIcon class="card-type-icon" icon="contactless-pay"></SVGIcon>
        <div class="text">
          <p class="title">
            Digital Wallet Card
            <b-badge class="-beta">BETA</b-badge>
          </p>
          <p>
            Create an unlocked Digital Wallet card, which can be reused at
            multiple merchants.
          </p>
        </div>
        <SVGIcon icon="chevron-right"></SVGIcon>
      </div>
    </button>
  </b-modal>
</template>

<script lang="ts">
import { CardType } from "@/types/Card";
import { Component, Emit, Vue } from "vue-property-decorator";
import { subscriptionStore } from "@/store";
import SVGIcon from "../SVGIcon.vue";

@Component({
  components: {
    SVGIcon,
  },
})
export default class SelectCardTypeModal extends Vue {
  @Emit()
  select(type: string): CardType | string {
    let cardTypeName = "Virtual";
    if (type === "cl") {
      cardTypeName = "Category Locked";
    } else if (type === "dw") {
      cardTypeName = "Digital Wallet";
    }
    this.$piwik.trackClick({
      name: `Create New ${cardTypeName} Card CTA`,
    });
    this.$bvModal.hide("card-type-modal");

    if (type === "dw") {
      return CardType.DIGITAL_WALLET;
    } else if (type === "cl") {
      return CardType.UNLOCKED;
    }

    return "";
  }

  get canCreateCategoryCards() {
    return !!this.subscription?.features.categoryCards;
  }

  get canCreateDigitalWalletCards() {
    return !!this.subscription?.features.digitalWalletCards;
  }

  get subscription() {
    return subscriptionStore.getters.subscription;
  }

  onShow(): void {
    this.$piwik.trackEvent("Modal", "View", {
      name: "Create New Card",
    });
    if (!this.canCreateCategoryCards) {
      this.$piwik.trackContentImpression(
        "Select Card Type Modal",
        "Create New Category Locked Card Upsell",
        "/select-plan"
      );
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .type-modal-header {
    padding: 32px 32px 0;
    font-family: $font-stack-fk-grotesk;
  }

  .type-modal-body {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

button {
  margin: 0 0 12px;
  padding: 0;
  border: none;
  background: none;
  text-align: left;
}

.category-card-button:disabled ::v-deep .card-title {
  color: #000000;
}

.category-card-upgrade {
  color: #323242;
}

.card-type {
  font-family: $font-stack-graphik;
  display: flex;
  padding: 24px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #ebebff;
  gap: 24px;
  max-width: 554px;

  .text {
    padding: 0;
    flex-direction: column;
    .title {
      font-size: 16px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    p {
      margin-bottom: 0;
    }
  }
  box-shadow:
    0px 0px 0px 1px rgba(48, 44, 44, 0.08),
    0px 2px 8px 0px rgba(0, 0, 0, 0.08);

  &.-with-promo {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 1px solid #e0e0e0 !important;
    border-left: 1px solid #e0e0e0 !important;
    border-right: 1px solid #e0e0e0 !important;
    border-bottom: 0px;
    box-shadow: 0px 0px 0px 0px;
    pointer-events: none;
  }

  &:hover {
    background-color: $brand-purple-10;
  }
}

.upgrade-prompt {
  display: flex;
  border: 1px solid #e0e0e0;
  border-top: 0px;
  border-radius: 0 0 8px 8px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: $brand-purple-10;
  max-width: 554px;
  font-size: 16px;

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.link {
  text-decoration: none;
}

.upgrade-button {
  background-color: $brand-purple;
  padding: 4px 12px;
  height: 32px;
  border-radius: 40px;
  margin-bottom: 0;
  text-decoration: none;
  color: white;

  &:hover {
    background-color: $brand-purple;
  }
}

.card-type-icon {
  @media #{$media-phone} {
    display: none;
  }
  ::v-deep path {
    fill: $brand-purple;
  }
}

::v-deep .badge {
  border-radius: 0.25rem;
  font-family: $font-stack-graphik;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.1px;
  &.-beta {
    background-color: $brand-purple-10;
    color: $brand-purple;
  }
}

.disabled-icon {
  ::v-deep path {
    fill: #b8b8cc;
  }
}
</style>
