export enum FeatureFlags {
  COMMERCIAL_CHARGE_REISSUE = "commercial-charge-reissue",
  NOTIFICATION_BANNER = "notification-banner",
  DURBIN_EXEMPT_BINS = "durbin-exempt-bins",
  CARD_PAGE_UPDATES_V1 = "card-page-updates-v1",
  REFRESH_BANK_TOKEN = "refresh-bank-token",
  DISPLAY_API_WIDGET = "display-api-widget",
  EXISTING_VISA_DEBIT_USERS = "existing-visa-debit-users",
  SHOW_FULL_BUSINESS_ONBOARDING = "show-full-business-onboarding",
}
