import Vue from "vue";
import axios, { AxiosResponse } from "axios";
import { getDate } from "@/lib/dates";

import { MutationHandler, ActionHandler } from "./vuex-typex";
import { userStore } from "./";
import { RootState, storeBuilder } from "./storeBuilder";
import {
  Plan,
  PlanData,
  PlanLimits,
  Subscription,
  SubscriptionStoreState,
  SubscriptionType,
  SubscriptionState,
  SubscriptionFundingType,
} from "../types/Subscription";

export const DEFAULT_CARD_CREATE_LIMIT = 12;

const BASE_PATH = "/api/v2/subscriptions";
const PLAN_TYPE_COOKIE = "planType";

const ISSUING_PLANS = [
  SubscriptionType.API_ISSUING,
  SubscriptionType.STARTER_ISSUING,
];

// @ts-ignore - we don't care about missing properties
const PLANS_KEY_MAP: Record<SubscriptionType, string> = {
  [SubscriptionType.FREE]: "free",
  [SubscriptionType.CONSUMER_PLUS]: "plus",
  [SubscriptionType.PROSUMER]: "pro",
  [SubscriptionType.SMB_TEAM]: "team",
  [SubscriptionType.API_ISSUING]: "issuing",
  [SubscriptionType.STARTER_ISSUING]: "freeIssuing",
  [SubscriptionType.EMPLOYEE]: "employee",
};

const builder = storeBuilder.module<SubscriptionStoreState>(
  "subscription",
  new SubscriptionStoreState()
);

type SubscriptionMutation<Payload = void> = MutationHandler<
  SubscriptionStoreState,
  Payload
>;
type SubscriptionAction<Payload = void, Type = void> = ActionHandler<
  SubscriptionStoreState,
  RootState,
  any,
  Payload,
  Type
>;

// #region Getters

const getPlanData = builder.read((state) => {
  const plans = state.plans;
  const base = "/assets/images/business";

  return plans.map((plan) => {
    const type = PLANS_KEY_MAP[plan.type];
    const iconSvg = `${base}/plan-icon-${type}.svg`;

    return {
      ...plan,
      displayType: type,
      iconSvg,
    };
  });
}, "plans");

const getSubscription = builder.read(
  (state) => state.subscription,
  "subscription"
);

const isPaidSubscription = builder.read(
  (state) => !!state.subscription?.nextChargeDate,
  "isPaidSubscription"
);

const isIssuingUser = builder.read((state): boolean => {
  const user = userStore.getters.currentUser;

  return !!(
    state.subscription?.issuingPlan || user?.organization?.pendingIssuing
  );
}, "isIssuingUser");

const planRequiresOrganization = builder.read((state, getters): boolean => {
  const currentPlan = getters.currentPlan;
  const selectedPlan = getters.selectedPlan();

  return !!(currentPlan?.organization || selectedPlan?.organization);
}, "planRequiresOrganization");

const newPlanRequiresOrganization = builder.read((_state, getters): boolean => {
  const selectedPlan = getters.selectedPlan();

  return selectedPlan?.organization;
}, "newPlanRequiresOrganization");

const getSelectedPlanLimits = builder.read((state): PlanLimits => {
  return {
    cardsCreatedLast30: state.subscription?.cardsCreatedLast30 || 0,
    cardsPerMonth: state.subscription?.cardsPerMonth || 12,
  };
}, "limits");

const getCurrentPlan = builder.read((state, getters) => {
  const plans: PlanData[] = getters.plans;
  const plan = plans.find(
    (plan) => plan.type === state.subscription?.subscriptionType
  );

  return plan;
}, "currentPlan");

const getSelectedPlanCookie = builder.read(
  () => () => {
    return Vue.$cookies.get(PLAN_TYPE_COOKIE);
  },
  "selectedPlanCookie"
);

const getCurrentlySelectedPlan = builder.read(
  (state, getters) => () => {
    const cookie = getters.selectedPlanCookie();

    if (!cookie) {
      return;
    }

    const plans = state.plans;
    return plans.find((plan) => plan.uuid === cookie);
  },
  "selectedPlan"
);

const isIssuingPlan = builder.read(
  () => (planType?: SubscriptionType) => {
    return ISSUING_PLANS.includes(planType as any);
  },
  "isIssuingPlan"
);

const isSubscribing = builder.read(
  (_state, getters) => () => {
    const selectedPlan = getters.selectedPlan();
    const currentPlan = getters.currentPlan;

    return selectedPlan && selectedPlan.uuid !== currentPlan?.uuid;
  },
  "isSubscribing"
);

export const getters = {
  get currentPlan() {
    return getCurrentPlan();
  },
  get selectedPlan() {
    return getCurrentlySelectedPlan();
  },
  get selectedPlanCookie() {
    return getSelectedPlanCookie();
  },
  get subscription() {
    return getSubscription();
  },
  get isPaidSubscription() {
    return isPaidSubscription();
  },
  get plans(): PlanData[] {
    return getPlanData();
  },
  get plan(): (uuid: string) => Plan {
    return (uuid: string) => {
      const plans = getPlanData();
      return plans.find((plan) => plan.uuid === uuid)!;
    };
  },
  get freePlan(): Plan {
    return getPlanData().find((plan) => plan.type === SubscriptionType.FREE)!;
  },
  get isSubscribing() {
    return isSubscribing();
  },
  get isIssuingPlan(): (planType?: SubscriptionType) => boolean {
    return isIssuingPlan();
  },
  get isIssuingUser() {
    return isIssuingUser();
  },
  get planRequiresOrganization() {
    return planRequiresOrganization();
  },
  get newPlanRequiresOrganization() {
    return newPlanRequiresOrganization();
  },
  get limits() {
    return getSelectedPlanLimits();
  },
};

const setPlans: SubscriptionMutation<Plan[]> = (state, plans) => {
  state.plans = plans;
};

const setSubscription: SubscriptionMutation<Subscription> = (
  state,
  subscription
) => {
  state.subscription = subscription;
};

const addSubscriptionCardCount: SubscriptionMutation<number> = (
  state,
  addToCount
) => {
  state.subscription = {
    ...state.subscription!,
    cardsCreatedLast30: state.subscription!.cardsCreatedLast30 + addToCount,
  };
};

export const mutations = {
  setPlans: builder.commit(setPlans),
  setSubscription: builder.commit(setSubscription),
  addSubscriptionCardCount: builder.commit(addSubscriptionCardCount),
};

// #endregion

// #region Actions

interface SubscriptionResponse {
  subscription: Subscription;
  error?: string;
}

interface CreateSubscriptionData {
  fundingCardUuid?: string;
  subscriptionTypeUuid: string;
  subscriptionFundingType?: SubscriptionFundingType;
}
const createSubscription: SubscriptionAction<
  CreateSubscriptionData,
  Subscription
> = async (_context, data) => {
  const response: AxiosResponse<SubscriptionResponse> = await axios.post(
    BASE_PATH,
    data
  );
  const { subscription } = response.data;

  mutations.setSubscription(subscription);

  return subscription;
};

interface EditSubscriptionData {
  fundingCardUuid?: string;
  state?: SubscriptionState.ACTIVE | SubscriptionState.CANCELLED;
  subscriptionUuid: string;
  subscriptionFundingType?: SubscriptionFundingType;
}
const editSubscription: SubscriptionAction<
  EditSubscriptionData,
  Subscription
> = async (_context, options) => {
  const { subscriptionUuid, ...props } = options;

  const {
    data: { subscription },
  }: AxiosResponse<SubscriptionResponse> = await axios.patch(
    BASE_PATH + `/${subscriptionUuid}`,
    props
  );
  mutations.setSubscription(subscription);

  return subscription;
};

const fetchSubscription: SubscriptionAction = () => {
  return axios.get(BASE_PATH).then((response) => {
    const sub = response.data.data[0];
    mutations.setSubscription(sub);

    return sub;
  });
};

const fetchPlans: SubscriptionAction = () => {
  return axios.get(BASE_PATH + "/plans").then((response) => {
    mutations.setPlans(response.data.data);

    return response.data;
  });
};

const fetchPlan: SubscriptionAction<string, Plan> = (
  _context,
  uuid: string
) => {
  return axios.get(BASE_PATH + "/plans/" + uuid).then((response) => {
    return response.data;
  });
};

const deletePlan: SubscriptionAction<string, Subscription> = async (
  _context,
  subscriptionUuid
) => {
  const {
    data: { subscription },
  }: AxiosResponse<SubscriptionResponse> = await axios.delete(
    BASE_PATH + `/${subscriptionUuid}`
  );

  mutations.setSubscription(subscription);

  return subscription;
};

const setTemporaryPlanCookie: SubscriptionAction<{
  planType: string;
  numDays?: number;
}> = (context, { planType, numDays = 2 }) => {
  const cookieExpirationDate = getDate().add(numDays, "days").toDate();
  Vue.$cookies.set(PLAN_TYPE_COOKIE, planType, cookieExpirationDate);
};

const removeTemporaryPlanCookie: SubscriptionAction = () => {
  Vue.$cookies.remove(PLAN_TYPE_COOKIE);
};

const chargeOutstandingBalance: SubscriptionAction<
  void,
  Subscription | undefined
> = async () => {
  let currentSubscription = getters.subscription;

  if (
    !currentSubscription ||
    (currentSubscription.state !== SubscriptionState.DELINQUENT &&
      currentSubscription.state !== SubscriptionState.PENDING)
  ) {
    return currentSubscription;
  }

  try {
    const {
      data: { subscription },
    }: AxiosResponse<SubscriptionResponse> = await axios.post(
      BASE_PATH + `/${currentSubscription.subscriptionUuid}/charge`
    );

    mutations.setSubscription(subscription);

    currentSubscription = subscription;
  } catch (error) {
    // noop since we don't want to throw an error
    // if the charge fails (yet)
  }

  return currentSubscription;
};
export const actions = {
  chargeOutstandingBalance: builder.dispatch(chargeOutstandingBalance),
  createSubscription: builder.dispatch(createSubscription),
  editSubscription: builder.dispatch(editSubscription),
  fetchSubscription: builder.dispatch(fetchSubscription),
  fetchPlan: builder.dispatch(fetchPlan),
  fetchPlans: builder.dispatch(fetchPlans),
  delete: builder.dispatch(deletePlan),
  setTemporaryPlanCookie: builder.dispatch(setTemporaryPlanCookie),
  removeTemporaryPlanCookie: builder.dispatch(removeTemporaryPlanCookie),
};

// #endregion
